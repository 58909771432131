import { FC } from 'react';
import styled from 'styled-components';
import { squareSize } from '../../styles/helpers';
import { AvatarIcon } from '../Icon/Icons/Avatar.icon';

export type SizeOptions = 'small' | 'normal' | 'big';

type Props = {
  size?: number;
  profilePictureUrl?: string;
  className?: string;
};

export const ProfilePicture: FC<Props> = (props) => {
  const { size, className, profilePictureUrl } = props;

  return (
    <ProfilePictureWrapper className={className} size={size}>
      {profilePictureUrl ? (
        <img src={profilePictureUrl} alt={profilePictureUrl} />
      ) : (
        <StyledAvatarIcon />
      )}
    </ProfilePictureWrapper>
  );
};

const ProfilePictureWrapper = styled.div<{ size?: number }>`
  position: relative;
  border-radius: 50%;
  display: flex;
  overflow: hidden;
  flex-shrink: 0;

  ${(props) => props.size && squareSize(`${props.size}px`)}
`;

export const StyledAvatarIcon = styled(AvatarIcon)`
  margin: auto;
  ${squareSize('100%')};
`;
